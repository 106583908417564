<template>
  <v-container>
    <div class="d-flex align-center flex-wrap mb-2" style="gap: 20px">
      <v-btn small color="primary" @click.stop="getItems">
        <v-icon left>mdi-reload</v-icon>
        {{$t('common.recargar')}}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn small color="secondary" @click.stop="exportarTodos">
        <v-icon left>mdi-microsoft-excel</v-icon>
        {{$t('common.exportar')}}
      </v-btn>
    </div>

    <v-card>
      <app-table
        :search="search"
        :headers="headers"
        :items="items"
        item-key="ref"
        :loading="loading"
        show-select
        :page.sync="page"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [15, 50, -1],
        }"
        hide-default-footer
        checkbox-color="secondary"
        @page-count="pageCount = $event"
        @click:row="(_, { item }) => (detailsId = item.ref)"
      >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="items"
            v-model="inlineFilters"
          />
        </template>

        <template v-slot:item.fechafactura="{ item }">
          {{ parseDate(item.fechafactura) }}
        </template>

        <template v-slot:item.estadofactura="{ item }">
          <EstadoFactura :estado="item.estadofactura" />
        </template>

        <template v-slot:item.bifactura="{ item }">
          {{ item.bifactura | eur }}
        </template>
        <template v-slot:item.totalfactura="{ item }">
          {{ item.totalfactura | eur }}
        </template>
      </app-table>
    </v-card>

    <!-- <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        total-visible="7"
      ></v-pagination><
    </div> -->

    <v-dialog
      fullscreen
      transition="dialog-bottom-transition"
      :value="detailsId != null"
      @input="(v) => (v === false ? (detailsId = null) : null)"
    >
      <v-toolbar dense color="primary" style="position: sticky; top: 0; z-index: 10;" >
        <v-spacer></v-spacer>
        <v-btn @click.stop="detailsId = null" icon color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <DetalleFactura :key="detailsId" :detailsId="detailsId" />
    </v-dialog>
  </v-container>
</template>

<script>
import { perColumnFilter, parseDate, jsonToCsv } from "@/utils/index.js";

export default {
  components: {
    EstadoFactura: () => import("../components/EstadoFactura.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
    DetalleFactura: () => import("../components/DetalleFactura.vue"),
    AppTable: () => import("@/components/AppTable.vue"),
  },
  props: {
    _detailsId: Number | String,
  },
  data() {
    return {
      items: [],
      headers: [
        { text: "Fecha", value: "fechafactura", dataType: "date" },
        { text: "Nº Factura", value: "numerofactura" },
        { text: "Base Imp.", value: "bifactura", dataType : 'number' },
        { text: "Total Factura", value: "totalfactura", dataType : 'number' },
        { text: "Estado", value: "estadofactura", dataType: "select" },
      ].map((header) => {
        return {
          class: "text-no-wrap sticky-header",
          cellClass: "pa-2 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          alias: this.$t('facturas.'+header.value),
          ...header,
          text: this.$t('facturas.'+header.value),
        };
      }),
      search: null,
      desde: null,
      hasta: null,
      loading: false,
      page: 1,
      pageCount: null,

      inlineFilters: {},

      detailsId: this._detailsId || null,
    };
  },
  methods: {
    parseDate,
    async getItems() {
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `/facturas`,
      });

      this.items = data;
      this.loading = false;
    },
    exportarTodos() {
      const getDatatype = (key) =>
        (this.headers.find((h) => h.value == key) || {}).dataType;

      const items = this.items.filter((con) =>
        Object.entries(con).every(([key, value]) =>
          perColumnFilter(value, this.inlineFilters[key], getDatatype(key))
        )
      );

      jsonToCsv(
        items,
        this.headers,
        this.$t('menu.facturas') + "_" + parseDate(Date.now(), false, false)
      );
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style></style>
